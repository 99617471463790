.footer {
  background-color: #fff;
  color: #333;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  z-index: 1100;
}

.footer > a {
  color: #333;
  text-decoration: none;
}

.copyright {
  font-size: 14px;
  color:dimgray;
}

.socials {
  color: #333;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  font-size: 30px;
  width: 200px;
  justify-content: space-between;
}

.socials > a {
  color: #333;
  text-decoration: none;
  font-size: 30px;
  transition: 0.1s ease-in-out;
}

.socials > a:hover {
  color: #FE6148;
  transition: 0.1s ease-in-out;
}

.contact-button {
  font-size: 18px;
}

@media(max-width: 768px) {
  .copyright {
    font-size: 12px;
  }

}