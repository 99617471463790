/* TODO: Add slide in from left animation*/

.project-overlay {
  position: fixed;
  top: 120px;
  left: 0;
  height: calc(100% - 170px);
  width: 100vw;
  display: flex;
  flex-direction: row;
  flex-direction: column;
  background-color: #333;
}

.project-content {
  width: 100%;
  height: 100%;
  padding: 40px;
  display: grid;
  /* Minmax constrains width, avoids massive oversizing, the percentage being the minimum width */
  grid-template-columns: minmax(25%, 1fr) minmax(72%, 3fr);
  grid-template-rows: 1fr 1fr;
  gap: 40px;
  flex: 1;
  color: #fff;
  flex-direction: row;
  position: relative;
  overflow-x: hidden;
}

.project-content>h2,
h3,
h4 {
  text-transform: uppercase;
  padding: 5px 0;
}

.project-content>p {
  text-align: justify;
}

h2 {
  font-size: 2rem;
}

.project-content > a,
button {
  color: #333;
  cursor: pointer;
  border: none;
  transition: 0.1s ease;
  text-decoration: none;
}

a:hover,
button:hover {
  color: #FE6148;
}

.title-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.description {
  padding-top: 10px;
  text-align: justify;
}

.project-url {
  font-size: 50px;
  color: #fff;
  transition: 0.1s ease;
  display: inline-block;
  align-items: center;
  justify-content: center;
}

.play-icon:hover {
  color: #FE6148;
  transition: 0.1s ease;
}

.project-banner {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 16 / 9;
}

/* TODO: Size project screenshots exactly the same as the banner */

.project-screenshots {
  width: 100%;
  overflow-x: auto;
  display: flex;
  scroll-snap-type: x mandatory;
  padding-bottom: 20px;
}

.screenshot-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
  aspect-ratio: 16 / 9;
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff;
  transition: 0.2 ease;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  color: #FE6148;
  transition: 0.2 ease;
}

.audio-video-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.video-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  /* 16:9 Aspect Ratio */
}

.video-wrapper iframe {
  width: 100%;
  height: 100%;
}

/* TODO: Increase video player size on mobile view. */

.project-details {
  display: flex;
  flex-direction: column;
}

.release-date,
.developer,
.role {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.denominator-title {
  color: #999;
  font-size: 1rem;
  text-align: left;
}

.denominator-text {
  font-size: 1rem;
  text-align: right;
}

.role-title {
  font-size: 1rem;
  color: #999;
}

.role-text {
  font-size: 1rem;
  text-align: right;
  color: #FE6148;
}

/* For Firefox */
.project-overlay::-webkit-scrollbar {
  width: 8px;
  display: none;
}

.project-overlay::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

.mproject-overlay::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  border: 2px solid transparent;
}

.project-overlay::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

/* For Firefox */
.project-overlay {
  scrollbar-width: none;
  scrollbar-color: rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.1);
}

.close-bar {
  position: fixed;
  bottom: 50px;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 1) 100%);
  text-align: center;
  z-index: 1100;
}

.close-bar-button {
  color: #fff;
  font-size: 50px;
  background: transparent;
}

/* MOBILE VIEW */

@media (max-width: 768px) {
  .project-content {
    width: 100vw;
    padding: 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .project-media {
    padding-left: 0;
    padding-top: 20px;
  }

 

  .soundcloud-wrapper {
    height: 400px;
  }

  .audio-video-container {
    display: flex;
    flex-direction: column;
  }

  .project-videos {
    display: flex;
    flex-direction: column;
   }

}

@media (max-width: 375px) {
  .project-details h3 {
    font-size: 0.9rem;
  }

  p {
    font-size: 0.9rem;
  }
}