.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0;
  width: 100vw;
  padding-bottom: 50px;
}

.grid-item {
  text-align: center;
}

.image-wrapper {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 1920px;
  max-height: 1080px;
  overflow: hidden;
}

.project-image {
  width: 100%;
  height: auto;
  display: block;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  padding: 1rem;
  box-shadow: none;
}

.overlay>h3 {
  font-size: 3rem;
  color: #FE6148;
}

.overlay p {
  font-size: 1.5rem;
}

.role {
  gap: 1.5rem;
}

.image-wrapper:hover .overlay {
  opacity: 1;
}

.image-wrapper:hover .project-image {
  filter: blur(6px);
  transition: 0.3s ease;
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 16 / 9;
}

.mobile-close-container {
  text-align: center;
}

@media (max-width: 1024px) {
  .grid-container {
    grid-template-columns: 1fr;
  }

  .overlay>h3 {
    font-size: 1.5rem;
  }
  
  .overlay p {
    font-size: 0.8rem;
    text-align: center;
  }
  
  .role {
    width: 100%;
    gap: 0.8rem;
    align-items: center;
  }
}

@media (pointer: coarse) {
  .image-wrapper:hover .project-image {
    filter: none;
  }
}