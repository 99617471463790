.email-overlay {
  position: fixed;
  top: 120px;
  left: 0;
  width: 100%;
  height: calc(100% - 170px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333;
  z-index: 900;
}

.email-content {
  padding: 0 50px;
  position: relative;
  width: 50%;
  height: 100%;
}

.email-content > h1, h2 {
  text-transform: uppercase;
}

.email-header > h2,
.email-content > p {
  color: #fff;
}

.email-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.email-content > p {
  padding-bottom: 10px;
  text-align: left;
}

.email-close-button {
  position: relative;
  cursor: pointer;
  font-size: 75px;
  background: transparent;
  color: #fff;
}

.email-close-button:hover {
  color: #FE6148;
  transition: 0.1s ease;
}

.name-fields {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.email-message {
  height: 200px;
}

button[type="submit"] {
  font-size: 2rem;
  padding: 5px 40px;
  border: none;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  align-items: center;
}

button[type="submit"]:hover {
  background-color: #FE6148;
  color:#fff;
}

.email-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.social-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  padding: 20px;
}

.social-buttons > a {
  font-size: 40px;
  color: #fff;
  text-decoration: none;
}

@media (max-width: 768px) {
  .email-content {
    width: 100%;
    overflow-y: scroll;
  }

  .social-buttons {
    justify-content: space-between;
  }

}