.showreels-overlay {
    position: fixed;
    top: 120px;
    left: 0;
    height: calc(100% - 170px);
    width: 100vw;
    display: flex;
    flex-direction: column;
    background-color: #333;
    z-index: 1000;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}

.showreels {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    width: 100%;
    height: 100%;
    position: relative;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 40px;
}

@media (max-width: 768px) {
    .showreels {
        grid-template-columns: 1fr;
    }
  }

.video-wrapper {
    position: relative;
    overflow: hidden;
    transition: 0.1s ease-in-out;
}

.video-wrapper:hover {
    transform: translateY(-8px);
    transition: 0.1s ease-in-out;
}

.video-wrapper > iframe {
    position: absolute;
    width: 100%;
    border: none;
    top: 0;
    left: 0;
  }

  .close-bar {
    position: fixed;
    bottom: 50px;
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 1) 100%);
    text-align: center;
    z-index: 1100;
  }
  
  .close-bar-button {
    color: #fff;
    font-size: 50px;
    background: transparent;
  }
