.showreels-container {
    width: 100%;
    position: relative;
    cursor: pointer;
}

.showreels-thumbnail {
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    display: block;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    padding: 1rem;
    box-shadow: none;
  }

  .image-wrapper:hover .overlay {
    opacity: 1;
  }
  
  .image-wrapper:hover .showreels-thumbnail {
    filter: blur(6px);
    transition: 0.3s ease;
  }
