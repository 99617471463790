body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Teko';
  src: url('../public/fonts/Teko/Teko-Regular.ttf') format('truetype'),
}

@font-face {
  font-family: 'Oswald';
  src: url('../public/fonts/Oswald/Oswald-Regular.ttf') format('truetype'),
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('../public/fonts/BebasNeue/BebasNeue-Regular.ttf') format('truetype'),
}

body, h1, h2, h3, h4, h5, h6, p, a, span, div, input, button {
  font-family: 'Oswald', sans-serif;
  letter-spacing: 1px;
}

