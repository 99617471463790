.header {
  background-color: #fff;
  padding: 15px 30px;
  width: 100%;
  position: fixed;
  height: 120px;
  top: 0;
  left: 0;
  z-index: 1100;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.site-profile-logo {
  display: flex;
  max-width: 100%;
  align-items: center;
}

.profile {
  width: 100px;
  border-radius: 100%;
  object-fit: contain;
  flex-shrink: 0;
}

.logo {
  width: 500px;
  object-fit: contain;
}

@media (max-width: 768px) {
  .header {
    justify-content: center;
  }
  
  .site-profile-logo {
    flex-direction: column;
    object-fit: contain;
    gap: 5px;
  }

  .profile {
    width: 75px;
  }

  .logo {
    width: 350px;
  }
}

.contact-button > a {
  color: #333;
  font-size: 30px;
  text-decoration: none;
  transition: 0.1s ease-in-out;
}

.contact-button > a:hover {
  color: #FE6148;
  transition: 0.1s ease-in-out;
}